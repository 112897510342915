<template>
	<main>
		<transition name="fade" mode="out-in">
			<div v-if="error" class="toast toast-error">
				<button @click="error=null" class="btn btn-clear float-right"></button>
				{{ error.mensaje }}
			</div>
		</transition>
		<section class="card">
			<div class="card-header text-center">
				<div class="card-title h3">Iniciar Sesión</div>
			</div>
			<div class="card-body">
				<form @submit.prevent="ingresar()" autocomplete="off">
					<fieldset :disabled="cargando">
						<div class="form-group">
							<label class="form-label text-bold" for="input-correo">Correo:</label>
							<div class="has-icon-left">
								<input v-model="form.correo" class="form-input" type="email" id="input-correo">
								<i class="form-icon icon icon-mail"></i>
							</div>
						</div>
						<div class="form-group">
							<label class="form-label text-bold" for="input-password">Contraseña</label>
							<div class="has-icon-left">
								<input v-model="form.password" class="form-input" type="password" id="input-password">
								<i class="form-icon icon icon-arrow-right"></i>
							</div>
						</div>
						<button type="submit" class="btn btn-block btn-primary mt-3" :class="cargando ? 'loading' : ''">Ingresar</button>
					</fieldset>
				</form>
			</div>
		</section>
		
	</main>
</template>

<script>
	export default {
		name: 'Ingresar',
		data() {
			return {
				cargando: false,
				error: null,
				form: {
					correo: '',
					password: ''
				}
			}
		},
		mounted() {
			document.title = 'Ingresar';
			this.$store.commit('setUser', {});
			localStorage.removeItem('WuipiToken');
		},
		methods: {
			ingresar() {
				this.cargando = true;
				this.error = null;
				this.$store.state.api
					.post('auth/login', this.form)
					.then(res => {
						if(res.data.estado == 'ok') {
							this.$store.commit('crearAxios', res.data.token);
							localStorage.setItem('WuipiToken', res.data.token);
						}
						else {
							console.log(res.data);
						}
					})
					.catch(err => this.error = err.response.data)
					.finally(() => {
						this.$router.push('/');
						this.cargando = false;
					});
			}
		}
	};
</script>

<style scoped>
	main {
		width: 100%;
		height: 100vh;
		background: rgb(79,37,128);
		background: linear-gradient(129deg, rgba(79,37,128,1) 0%, rgba(34,193,195,1) 100%);
		display: grid;
		place-items: center;
	}
	.card {
		padding: 1.5rem;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	}
	.toast {
		position: fixed;
		top: 1rem;
		width: 90%;
		margin: 0 auto;
	}
	.mt-3 {
		margin-top: 1.5rem;;
	}
</style>
